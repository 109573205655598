import React, { PureComponent, ReactNode, MouseEvent } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Swipeable } from 'react-swipeable'
import Icon from '@material-ui/core/Icon'
import { WindowLocation } from '@reach/router'

import Layout from '../components/layout'
import Section, {Header, SubHeader, Divider} from '../components/section/section'
import Modal from '../components/modal'
import ImageGallery, { ImageNode } from '../components/imageGallery'
import theme from '../theme'

type Props = {
  location: WindowLocation,
  children: ReactNode[],
  data: {
    images: {
      edges:  ImageNode[]
    } | null
  }
}

type State = {
  toggledImageIndex: number | null,
  images: ImageNode[] | undefined,
  isModalOpen: boolean
}

export default class Gallery extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    this.toggleImage = this.toggleImage.bind(this)
    this.previousImage = this.previousImage.bind(this)
    this.nextImage = this.nextImage.bind(this)

    const images = this.props.data.images!.edges.map(edge => {
      if (!edge.node.name.includes('360')) {
        return edge
      }
    })

    this.state = {
      toggledImageIndex: null,
      images: images.filter(Boolean),
      isModalOpen: false
    }
  }

  toggleImage(event: MouseEvent) {
    if (event.target && event.target.src) {
      const imageSrc = new URL(event.target.src).pathname
      let imageIndex = -1

      this.state.images!.some((edge, index) => {
        if (edge.node.childImageSharp.fluid.src === imageSrc) {
          imageIndex = index
          return true
        }
      })

      this.setState({
        toggledImageIndex: imageIndex,
        isModalOpen: true
      })
    } else {
      this.setState({
        isModalOpen: false
      })
    }
  }

  previousImage() {
    let imageIndex = -1

    this.state.images!.map((edge, index) => {
      if (imageIndex === -1 && edge.node.childImageSharp.fluid.src === this.state.images![this.state.toggledImageIndex!].node.childImageSharp.fluid.src) {
        if (index - 1 >= 0) {
          imageIndex = index - 1
        } else {
          imageIndex = this.state.images!.length - 1
        }
      }
    })

    this.setState({ 
      toggledImageIndex: imageIndex
    })
  }

  nextImage() {
    let imageIndex = -1

    this.state.images!.map((edge, index) => {
      if (imageIndex === -1 && edge.node.childImageSharp.fluid.src === this.state.images![this.state.toggledImageIndex!].node.childImageSharp.fluid.src) {
        if (index + 1 < this.state.images!.length) {
          imageIndex = index + 1
        } else {
          imageIndex = 0
        }
      }
    })

    this.setState({ 
      toggledImageIndex: imageIndex
    })
  }

  render() {
    const modalizedImage = this.state.isModalOpen ? (
      <Modal handleClose={this.toggleImage}>
        <Icon onClick={this.previousImage} style={{ fontSize: '36px', color: 'white', cursor: 'pointer' }}>navigate_before</Icon>
            <Swipeable 
              trackMouse={true}
              preventDefaultTouchmoveEvent={true}
              onSwipedLeft={this.nextImage} 
              onSwipedRight={this.previousImage}
              style={{ maxWidth: '90vw', width: '100vw' }}>
              <Img sizes={this.state.images![this.state.toggledImageIndex!].node.childImageSharp.fluid} style={{pointerEvents: 'none'}}/>
            </Swipeable>
        <Icon onClick={this.nextImage} style={{ fontSize: '36px', color: 'white', cursor: 'pointer' }}>navigate_next</Icon>
      </Modal>
    ) : null

    return(
      <Layout location={this.props.location}>
        <Section 
          width="100%"
          margin="0"
          padding="40px 0"
          flexDirection="column"
          background="whitesmoke">
          <Header title="Gallery"/>
          <SubHeader>
            Take a glance at our space and some of our past events.
          </SubHeader>
          <Divider themeColor={theme.palette.primary.main}/>
          <ImageGallery
            images={this.state.images ? this.state.images : [] }
            toggleImage={this.toggleImage} />
        </Section>
        {modalizedImage}
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query Images {
    images: allFile(
      filter: {
        extension: { regex: "/(jpe?g)/" }, 
        sourceInstanceName: { eq: "images" }
      }) {
      edges {
        node {
          name,
          childImageSharp {
            fluid(maxWidth: 1240) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`